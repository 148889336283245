<template>
  <div>
    <div class="row">
      <div class="col-12">
        <vue-dropzone :useCustomSlot=true ref="dropzone" id="dropzone" v-on:vdropzone-success="uploadProdutosdeArquivo" v-on:vdropzone-removed-file="limpaProdutosSelecionados" :options="dropzoneOptions">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              Arraste e solte para importar de um arquivo!
            </h3>
            <div class="dropzone-custom-subtitle">...ou clique para selecionar um arquivo do seu computador</div>
          </div>
        </vue-dropzone>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <span class="clientes filters__search">
          <input type="text" v-model="filter" placeholder="Filtrar por id ou descrição">
          <img src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K" />
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="scrollable lista-clientes" style="height: 300px;">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th>ID</th>
                <th>Descrição</th>
                <th>#</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="produto in produtosFiltered">
                <td>{{ produto.id }}</td>
                <td>{{ produto.descComp }}</td>
                <td>
                  <button v-on:click="onSelectProduto(produto.id)" class="button button-success">
                    <svg x="0px" y="0px" viewBox="0 0 42 42" style="width: 12px; height: 12px; fill: rgb(255, 255, 255);"><path d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"></path></svg>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="scrollable lista-clientes" style="height: 300px;">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th colspan="3">
                  Produtos Vinculados
                  <small class="small-text-info text-gray">({{ produtosSelecionados.length}}/{{produtos.length}})</small>
                  <span class="button button-link pull-right" v-if="produtosSelecionados.length" v-on:click="limpaProdutosSelecionados" title="Limpar todos">Limpar todos <fa-icon icon="trash-alt" /></span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="produto in produtosSelecionados">
                <td class="highlight">{{ produto.id }}</td>
                <td class="highlight">{{ produto.descComp }}</td>
                <td class="actions">
                  <button type="button" name="button" v-on:click="onRemoveProduto(produto.id)" class="button button-error">
                    <fa-icon icon="trash-alt" />
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#dropzone {
  position: relative;
}
.dropzone-custom-content {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
}

.dropzone-custom-title {
  color: #00b782;
  font-size: 1.5rem;
  margin: 0 0 0.6rem;
  background-color: initial;
}

.dropzone-custom-subtitle {
  color: #314b5f;
}
</style>

<script>

  import vue2Dropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";  
  import setup from "../../../config";
  import _some from "lodash/some";
  import _find from "lodash/find";
  import _concat from "lodash/concat";
  import _filter from "lodash/filter";
  import _difference from "lodash/difference";
  import { toMonetary, isNumeric } from "../../../helpers/utils";
  

  const dropzoneDictionary = {
    dictDefaultMessage:
      "Arraste um arquivo .xls(x) ou clique para importar produtos",
    dictFallbackMessage:
      "Seu navegador não suporta upload o recurso de arrastar e soltar",
    dictFileTooBig: "O arquivo excedeu o tamanho máximo permitido (5MB)",
    dictInvalidFileType: "Arquivo no formato inválido",
    dictResponseError: "Erro ao processar solicitação, tente novamente!",
    dictCancelUpload: "Cancelar",
    dictCancelUploadConfirmation: "Tem certeza?",
  };

  export default {
    name: "cupomProdutos",
    components: {
      vueDropzone: vue2Dropzone,
    },
    props: [
      "item",      
      "produtos",
    ],
    data() {
      return {
        filter: "",
        dropzoneOptions: {
          url: `${setup.api}/admin/cupons/importar-produtos`,
          acceptedFiles: ".xls, .xlsx",
          maxFilesize: 5,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          paramName: "produtos",
          ...dropzoneDictionary,
        },
      };
    },
    methods: {
      uploadProdutosdeArquivo(arquivo, resposta) {
        const idsValidos = _filter(resposta.produtos, (id) => {
          return _find(this.produtos, (produto) => `${produto.id}` === `${id}`);
        });
        const newClients = _difference(idsValidos, this.item.produtos);
        this.item.produtos = _concat(this.item.produtos, newClients);
      },
      onSelectProduto(id) {
        this.item.produtos.push(id);        
      },
      onRemoveProduto(id) {
        this.item.produtos = _filter(this.item.produtos, (idProduto) => {
          return +id !== +idProduto;
        });
      },
      limpaProdutosSelecionados() {
        this.$refs.dropzone.removeAllFiles();
        this.item.produtos = [];
      },
    },
    computed: {
      produtosFiltered() {
        if (!this.item.produtos) {
          return [];
        }

        const toFilter = this.produtos.filter((loja) => {
          return !this.item.produtos.some((id) => `${id}` === `${loja.id}`);
        });

        let text = this.filter;

        if (text) {
          return toFilter
            .filter((loja) => {
              text = text.toLowerCase();

              // Filtro por ID
              if (isNumeric(text) && loja.id && loja.id === text) {
                return true;
              }

              // Filtro por Descrição
              if (loja.descComp && loja.descComp.toLowerCase().match(text)) {
                return true;
              }
            })
            .slice(0, this.resultLimit);
        }

        return toFilter.slice(0, this.resultLimit);
      },
      produtosSelecionados() {
        if (this.item.produtos && !this.item.produtos.length === 0) {
          return [];
        }
        return _filter(this.produtos, (produto) => {
          return (
            _some(this.item.produtos, (id) => `${produto.id}` === `${id}`) ||
            false
          );
        });
      },
    },
  };
</script>
