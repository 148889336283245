<template>
  <div>
    <Edit
      :dimensoes="dimensoes"
      :clientes="clientes"
      :produtos="produtos"
      :item="currentItem"
      :update="updateItem"
      :close="closeEdit"
      :error="modalError"
      :descSingular="descSingular"
    ></Edit>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab :title="descPlural">
        <div
          class="filters xrow d-flex align-items-end"
          style="
            margin-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div class="col-1-4">
            <input
              class="form-control"
              type="text"
              v-model="filtros.codigo"
              placeholder="Código"
            />
          </div>
          <div class="col-1-4">
            <select class="form-control" v-model="filtros.status">
              <option value="TODOS">Todos</option>
              <option value="NAO_UTILIZADO">Não Utilizados</option>
              <option value="UTILIZADO">Já Utilizados</option>
            </select>
          </div>
          <div class="col-1-4">
            <div class="form-group d-flex" style="margin-bottom: 0">
              <div style="margin-right: 20px">
                <label>Dt. inicial</label>
                <datetime
                  input-class="form-control"
                  v-model="filtros.dtIni"
                  type="date"
                  v-on:input="loadCupons"
                  placeholder="Data inicial"
                  :phrases="{ ok: 'Confirmar', cancel: 'Fechar' }"
                  value-zone="local"
                />
              </div>

              <div>
                <label>Dt. final</label>
                <datetime
                  input-class="form-control"
                  v-model="filtros.dtFim"
                  type="date"
                  v-on:input="loadCupons"
                  placeholder="Data final"
                  :phrases="{ ok: 'Confirmar', cancel: 'Fechar' }"
                  value-zone="local"
                />
              </div>
            </div>
          </div>
          <div class="col-1-4">
            <button class="button button-primary" v-on:click="loadCupons">
              Buscar
            </button>
          </div>
          <div class="col-1-2">
            <div class="pull-right">
              <button
                v-on:click="openCreate"
                class="button button-success button-block"
              >
                <fa-icon icon="plus" />
                Criar
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Id</th>
                <th>Código</th>
                <th>Tipo Desc.</th>
                <th>Valor</th>
                <th>Clientes</th>
                <th>Limite p/ Cliente</th>
                <th>Usados</th>
                <th>Validade</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items">
                <td class="highlight">{{ item.id }}</td>
                <td>{{ item.codigo }}</td>
                <td>{{ tiposDesconto()[item.tipoDesconto].label }}</td>

                <td v-if="item.tipoDesconto === 'F'">
                  {{ toMonetary(item.valor) }}
                </td>
                <td v-else>{{ item.valor }}%</td>

                <td v-if="item.validarCliente">
                  {{ item.clientes.length }} cliente(s)
                </td>
                <td v-else>Todos</td>

                <td>{{ item.limiteUsos }}</td>
                <td>{{ item.usados }}</td>
                <td>
                  {{
                    toDateTime(item.dataInicio) +
                    " - " +
                    toDateTime(item.dataFim)
                  }}
                </td>
                <td>
                  <span
                    :class="{
                      label: true,
                      success: item.status === 'ATIVO',
                      error: item.status === 'VENCIDO',
                    }"
                  >
                    {{ item.status }}
                  </span>
                </td>
                <td class="actions">
                  <button
                    type="button"
                    :class="{
                      'button button-info': true,
                      'button-disabled': !item.usados,
                    }"
                    v-on:click="openInfo(item)"
                    title="Histórico de uso"
                  >
                    <fa-icon icon="info-circle" />
                  </button>
                  <button
                    type="button"
                    class="button button-success"
                    v-on:click="cloneCupom(item)"
                  >
                    <fa-icon icon="clone" />
                  </button>
                  <button
                    type="button"
                    class="button button-primary"
                    v-on:click="openEdit(item)"
                  >
                    <fa-icon icon="pencil-alt" />
                  </button>
                  <button
                    type="button"
                    name="button"
                    v-on:click="deleteConfirm(item.id)"
                    class="button button-error"
                  >
                    <fa-icon icon="trash-alt" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CTab>
    </CTabs>
    <Historico :historico="historico" :close="closeInfo"> </Historico>
  </div>
</template>

<script>
import clone from "just-clone";
import { get, post, put, del } from "@/helpers/apiRequest";
import { toMonetary, toPercent, toDateTime } from "../../helpers/utils";
import _map from "lodash/map";
import moment from "moment";
import Edit from "./Edit";
import Historico from "./Historico";

const initial = {
  codigo: "",
  orientacao: "",
  tipoDesconto: "F",
  tipoRegra: "P",
  tipoIntegracao: "CREDITO",
  valor: "1.00",
  dataInicio: moment().format(),
  clientes: [],
  produtos: [],
  regras: [],
  exclusoes: [],
  B2B: false,
  CALLCENTER: false,
  FORCA_VENDAS: false,
  dataFim: moment().add(1, "days").format(),
  ativo: 1,
  validarCliente: false,
  limiteUsos: "1",
  limiteusos_geral: null,
  valor_maximo_permitido: "1.00",
  aplica_sobre_imposto: 1,
  aplica_sobre_promocao: 0,
  valida_valor_minimo_plano: 1,
};

export default {
  name: "cupons",
  components: {
    Edit,
    Historico,
  },
  data() {
    return {
      descPlural: "",
      descSingular: "",
      loading: false,
      items: [],
      clientes: [],
      produtos: [],
      dimensoes: [],
      filtros: {
        dtIni: moment().format("YYYY-MM-DD"),
        dtFim: moment().format("YYYY-MM-DD"),
        status: "TODOS",
        codigo: "",
      },
      currentItem: { ...initial },
      currentAccess: false,
      modalError: null,
      historico: null,
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem("app"));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === "ADMIN_CUPOM"
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    setStatus({ loading, error }) {
      this.loading = loading;
      this.modalError = error;
    },
    resetStatus() {
      this.loading = false;
      this.modalError = null;
    },
    loadCupons() {
      this.loading = true;
      const dataIniFormat = moment(this.filtros.dtIni).format("YYYY-MM-DD");
      const dataFimFormat = moment(this.filtros.dtFim).format("YYYY-MM-DD");

      get(
        `/admin/cupons?dtIni=${dataIniFormat}&dtFim=${dataFimFormat}&status=${this.filtros.status}&codigo=${this.filtros.codigo}`
      )
        .then((json) => {
          this.items = this.midllewareCupons(json);
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    midllewareCupons(cupons) {
      return _map(cupons, (i) => {
        return {
          ...i,
          dataInicio: moment(i.dataInicio).format(),
          dataFim: moment(i.dataFim).format(),
        };
      });
    },
    loadClientes() {
      this.loading = true;
      get("/admin/cupons-clientes")
        .then((array) => {
          this.clientes = array;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadProdutos() {
      this.loading = true;
      get("/admin/cupons-produtos")
        .then((array) => {
          this.produtos = array;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadDimensoes() {
      this.loading = true;
      get("/admin/cupons-dimensoes")
        .then((json) => {
          this.dimensoes = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    cloneCupom(cupom) {
      const cloneCupom = clone(cupom);

      cloneCupom.id = null;
      cloneCupom.codigo = null;

      this.openEdit(cloneCupom);
    },
    createItem(item) {
      const payload = { ...item };
      const idClientes = _map(payload.clientes, 'id');
      payload.clientes = idClientes;
      
      this.loading = true;
      post("/admin/cupons", payload)
        .then((json) => {
          this.$swal({
            title: "Sucesso!",
            text: "Cupom cadastrado com sucesso!",
            icon: "success",
          });
          this.closeEdit();
          this.resetStatus();
          this.loadCupons();
        })
        .catch((error) => {
          this.setStatus({ loading: false, error });

          this.$swal({
            title: "Falha ao Salvar",
            text: error.message,
            showCancelButton: true,
            cancelButtonText: "Fechar",
            showConfirmButton: false,
            icon: "error",
          });
        });
    },
    openInfo(item) {
      if (!item.usados) {
        return;
      }

      this.loading = true;
      get(`/admin/cupons/${item.id}/historico`)
        .then((json) => {
          this.historico = json;
          this.$modal.show("cupomHistorico");
        })
        .catch((err) => console.log(`Erro ao carregar histórico: ${err}`))
        .finally(() => (this.loading = false));
    },
    closeInfo() {
      this.$modal.hide("cupomHistorico");
    },
    openCreate() {
      this.currentItem = { ...initial };
      this.currentItem.clientes = [];
     
      this.$modal.show("cupomEdit");
      this.modalError = null;
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.$modal.show("cupomEdit");
      this.modalError = null;
    },
    closeEdit() {
      this.$modal.hide("cupomEdit");
      this.modalError = null;
    },
    updateItem() {
      this.loading = true;
      const { id } = this.currentItem;
      const payload = { ...this.currentItem };
      const idClientes = _map(payload.clientes, 'id');
      payload.clientes = idClientes;

      if (id) {
        put(`/admin/cupons/${id}`, payload)
          .then((json) => {
            this.$swal({
              title: "Sucesso!",
              text: "Cupom alterado!",
              icon: "success",
            });
            this.loading = false;
            this.loadCupons();
            this.closeEdit();
          })
          .catch((error) => {
            this.setStatus({ loading: false, error });

            this.$swal({
              title: "Falha ao Salvar",
              text: error.message,
              showCancelButton: true,
              cancelButtonText: "Fechar",
              showConfirmButton: false,
              icon: "error",
            });
          });
      } else {

        this.createItem(this.currentItem);
      }
    },
    deleteConfirm(id) {
      this.$swal({
        title: "Confirma Remover?",
        text: "Essa ação não poderá ser desfeita",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/cupons/${id}`)
              .then((json) => {
                this.loading = false;
                this.loadCupons();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: "Falha ao remover",
                  icon: "error",
                });
              });
          }
        })
        .catch(() => {});
    },
    tiposDesconto() {
      return {
        F: {
          label: "Fixo",
          symbol: "R$",
        },
        P: {
          label: "Percentual",
          symbol: "%",
        },
      };
    },
    toMonetary,
    toPercent,
    toDateTime,
  },
  beforeMount() {
    this.loadClientes();
    this.loadProdutos();
    this.loadCupons();
    this.loadDimensoes();
    this.loadDescricaoModulo();
  },
};
</script>
