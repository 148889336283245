<template>
    <div class="xrow">
        <h2>
            Exclusões:
        </h2>
        <br/>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Tipo</th>
                    <th>Valor</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
              
                <tr v-for="(item, index) in item.exclusoes">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.tipo }}</td>
                    <td>{{ item.chave }}</td>
                    <td>
                        <button class="button button-error"
                                title="Remover"
                                v-on:click="()=>remover(index)"
                        >
                            <strong>&times;</strong>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h2>Adicionar:</h2>
        <div class="col-1-4">
            <div class="form-group">
                <label>Tipo:</label>
                <label>
                    <select class="form-control" v-model="exclusao.tipo">
                        <option value="FILIAL">Filial</option>
                        <option value="CLIENTE">Cliente</option>
                        <option value="REGIAO">Região (Tabela de Preço)</option>
                        <option value="REGIAO_RCA">Região (Representante)</option>
                        <option value="REDE">Rede</option>
                        <option value="PRACA">Praça</option>
                        <option value="ATIVIDADE">Atividade</option>
                        <option value="REPRESENTANTE">Representante</option>
                        <option value="SUPERVISOR">Supervisor</option>
                        <option value="UF">UF</option>
                    </select>
                </label>
            </div>
        </div>
        <div class="col-1-4">
            <div class="form-group">
                <div class="input-group">
                    <label>{{exibeLabel}}</label>
                    <label>
                         
                        <input class="form-control" maxlength="250" v-model="exclusao.chave" />
                        <button class="button button-primary input-group-addon-right"
                                :disabled="check()"
                                title="Adicionar"
                                v-on:click="adicionar"
                        >
                            +
                        </button>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import _find from 'lodash/find';

  export default {
    name: 'exclusaoCupom',
    props: ['item' ],
    watch: {
      'exclusao.tipo'() {
        this.exclusao.chave = '';
      },
    },
    computed: {
      exibeLabel() {
        switch (this.exclusao.tipo) {
          case 'FILIAL':
            return 'Código filial:';
          case 'CLIENTE':
            return 'Código cliente:';
          case 'REGIAO':
            return 'Código região:';
          case 'REGIAO_RCA':
            return 'Código região:';
          case 'REDE':
            return 'Código rede:';
          case 'PRACA':
            return 'Código praça:';
          case 'ATIVIDADE':
            return 'Código atividade:';
          case 'REPRESENTANTE':
            return 'Código RCA:';
          case 'SUPERVISOR':
            return 'Código supervisor:';
          case 'UF':
            return 'Código UF:';
          default:
            return 'Código:'
        }
      },
    },
    data() {
      return {
        exclusao: {
          tipo: '',
          chave: '',
        },
      };
    },
    methods: {
      adicionar() {
        this.item.exclusoes.push({...this.exclusao});
        this.resetForm();
      },
      remover(i) {
        this.item.exclusoes.splice(i, 1);
      },
      resetForm() {
        this.exclusao.tipo = '';
        this.exclusao.chave = '';
      },
      check() {
        return _find(this.item.exclusoes, {
          tipo: this.exclusao.tipo,
          chave: this.exclusao.chave,
        }) || this.exclusao.tipo  == '' || this.exclusao.chave  == '';
      },
    },
  };
</script>
