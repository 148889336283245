<template>
  <modal 
    name="cupomEdit" 
    height="auto" 
    width="900" 
    :scrollable="true" 
    @before-open="beforeOpen()"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <div class="bdti-alert bdti-alert-danger" v-if="error && error.message">{{ error.message }}</div>

        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`Criar ${descSingular}`">
            <CAlert show color="warning">
              ATENÇÃO: Mudamos o formato de desconto aplicado de cupons. Todo cupom transmitido através das nossas plataformas será através da geração de um crédito ao cliente para atender as normas da integradora de seu ERP.
            </CAlert>
            <div class="xrow">
              <div class="col-1-3">
                <div class="form-group">
                  <label>Código:</label>
                  <input class="form-control" maxlength="15" v-model="item.codigo"/>
                  <div class="message">{{ validation.firstError('item.codigo') }}</div>
                </div>
                <div class="form-group">
                  <label>Tipo de desconto:</label>
                  <select class="form-control" v-model="item.tipoDesconto" v-on:change="resetTipointegracao">
                    <option value="F">Valor Fixo</option>
                    <option value="P">Valor Percentual</option>
                  </select>
                  <div class="message"></div>
                </div>
                <div class="form-group" style="margin-bottom: 79px">
                  <label> Canais disponíveis: </label>
                  <label>
                    <input type="checkbox" :value="item.B2B" v-model="item.B2B">
                    B2B
                  </label>
                  <label>
                    <input type="checkbox" :value="item.CALLCENTER" v-model="item.CALLCENTER">
                    Callcenter
                  </label>
                  <label>
                    <input type="checkbox" :value="item.FORCA_VENDAS" v-model="item.FORCA_VENDAS">
                    Força de Vendas
                  </label>
                </div>
               
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label>Data de Início:</label>
                  <datetime
                      input-class="form-control"
                      type="datetime"
                      v-model="item.dataInicio"
                      :max-datetime="item.dataFim"
                      :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                      value-zone="local"
                  />
                  <div class="message">{{ validation.firstError('item.dataInicio') }}</div>
                </div>
                 <div class="form-group">
                  <label>Validar valor mínimo plano:</label>
                  <select class="form-control" v-model="item.valida_valor_minimo_plano">
                    <option value=1>Sim</option>
                    <option value=0>Não</option>
                  </select>
                  <div class="message"></div>
                </div>
                <div class="form-group" style="margin-bottom: 31px">
                  <label>Considera promoção:</label>
                  <select class="form-control" v-model="item.aplica_sobre_promocao">
                    <option value=1>Sim</option>
                    <option value=0>Não</option>
                  </select>
                </div>
                
                <div class="form-group">
                  <label>Limite de uso geral:</label>
                  <the-mask mask="###" class="form-control" v-model="item.limiteusos_geral"/>
                  <div class="message">{{ validation.firstError('item.limiteusos_geral') }}</div>
                </div>
                <div class="form-group">
                  <label>Valor máximo desconto permitido:</label>
                  <money :disabled="!isChecked" class="form-control" v-model="item.valor_maximo_permitido" v-bind="monetary"/>
                  <div class="message">{{ validation.firstError('item.valor_maximo_permitido') }}</div>
                  <label>
                    <input type="checkbox" v-model="isChecked">
                      Habilitar valor máximo
                  </label>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label>Data de Término:</label>
                  <datetime :disabled="item.dataInicio === null"
                            input-class="form-control"
                            type="datetime"
                            :min-datetime="item.dataInicio"
                            v-model="item.dataFim"
                            :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                            value-zone="local"
                  />
                  <div class="message">{{ validation.firstError('item.dataFim') }}</div>
                </div>
                <div class="form-group">
                  <label>Aplicar desconto sobre:</label>
                  <select class="form-control" v-model="item.aplica_sobre_imposto">
                    <option value=0>Preço sem imposto</option>
                    <option value=1>Preço com imposto</option>
                  </select>
                  <div class="message"></div>
                </div>
                <div class="form-group">
                  <label>Aplicar desconto em:</label>
                  <select class="form-control" v-model="item.tipoRegra">
                    <option v-if="item.tipoDesconto === 'P' && !hasRuleProdutos" value="D">Produtos das regras de
                      dimensões
                    </option>
                    <option v-if="item.tipoDesconto === 'P' && hasRuleProdutos" value="PR">Produtos das regras de
                      produtos
                    </option>
                    <option value="P">Total do pedido</option>
                  </select>
                  <div class="message"></div>
                </div>
                <div class="form-group">
                  <label>Limite de uso por cliente:</label>
                  <the-mask mask="###" class="form-control" v-model="item.limiteUsos"/>
                  <div class="message">{{ validation.firstError('item.limiteUsos') }}</div>
                </div>
                <div class="form-group" v-if="item.tipoDesconto === 'F'">
                  <label>Valor do desconto:</label>
                  <money class="form-control" v-model="item.valor" v-bind="monetary"/>
                  <div class="message">{{ validation.firstError('item.valor') }}</div>
                </div>
                <div class="form-group" v-if="item.tipoDesconto === 'P'">
                  <label>Percentual do desconto:</label>
                  <vue-numeric
                  currency="%"
                  currency-symbol-position="suffix"
                      v-bind:max="100"
                      v-bind:precision="2"
                      v-model="item.valor" class="form-control"
                  />
                  <div class="message">{{ validation.firstError('item.valor') }}</div>
                </div>

              </div>
            </div>
            <div class="xrow">
              <div class="col-1-1">
                <div class="form-group">
                  <label>Orientações ao cliente:</label>
                  <textarea rows="8" class="form-control" v-model="item.orientacao"/>
                  <div class="message">{{ validation.firstError('item.orientacao') }}</div>
                </div>
              </div>
            </div>
            <hr>
            <h2>CRIAR NOVA REGRA:</h2>
            <div class="xrow">
              <div class="col-1-4">
                <div class="form-group">
                  <label>Regra por:</label>
                  <select class="form-control" v-model="newRuleForm.type" v-on:change="resetNewRule">
                    <option v-if="!hasRuleProdutos" value="D">Dimensão</option>
                    <option v-if="!hasRulePedido && !hasRuleProdutos" value="P">Pedido</option>
                    <option v-if="!hasRulePedido && !hasRuleProdutos && !hasRuleDimensoes" value="PR">Produto</option>
                  </select>
                </div>
              </div>
              <template v-if="newRuleForm.type === 'D' && !hasRuleProdutos">
                <div class="col-1-4">
                  <div class="form-group">
                    <label>Quando:</label>
                    <select class="form-control" v-model="newRuleForm.dim" v-on:change="changeDimensao">
                      <option v-for="(dim, index) in dimensoes" :value="index">{{ dim.label }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-1-4">
                  <div class="form-group">
                    <label>For:</label>
                    <select class="form-control" v-model="newRule.dimensao.id">
                      <option v-for="(item, index) in getDimensoesItens" :key="index" :value="item.id"
                              :selected="index === 0">{{ item.desc }}
                      </option>
                    </select>
                  </div>
                </div>
              </template>
              <div class="col-1-4" v-if="!hasRuleProdutos">
                <div class="form-group">
                  <label v-if="newRuleForm.type !== 'PR'">Valor mínimo:</label>
                  <label v-if="newRuleForm.type === 'PR'">Valor mínimo do pedido:</label>
                  <div class="input-group">
                    <money class="form-control" v-model="newRule.valorMinimo" v-bind="monetary"/>
                    <button class="button button-primary input-group-addon-right" title="Adicionar" :disabled="isValorMinimoZero"
                            v-on:click="addRule(newRuleForm.type)">+
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="newRuleForm.type === 'D'" class="x-row">
              <CAlert show color="warning">
                <b>AVISO:</b> Para o cupom ser válido no momento da sua utlização, o pedido deve conter produtos que abranjam todas as dimensões definidas na regra abaixo. 
              </CAlert>
            </div>
            <div v-if="newRuleForm.type === 'P'" class="x-row">
              <CAlert show color="warning">
                <b>AVISO:</b>  Para o cupom ser válido no momento da sua utilização, o pedido deve conter o valor mínimo de acordo com a regra abaixo
              </CAlert>
            </div>
            <hr>
            <div class="xrow">
              <h2>REGRAS PARA ATIVAÇÃO:
                <small class="validation-error">{{ validation.firstError('item.regras') }}</small>
              </h2>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th v-if="!hasRuleProdutos">Valor Mínimo</th>
                    <th v-if="hasRuleProdutos">Valor Mínimo do Pedido</th>
                    <th>Regra</th>
                    <th>Ação</th>
                  </tr>
                  </thead>
                  <tbody>
                  <!--<tr v-if="!item.regras.length">-->
                  <!--<td colspan="4" style="text-align: center;">Sem regra definida</td>-->
                  <!--</tr>-->
                  <tr v-for="(regra, index) in item.regras">
                    <td>{{ index + 1 }}</td>
                    <td>{{ toMonetary(regra.valorMinimo) }}</td>
                    <td>{{ getDescricaoRegra(regra.dimensao, regra.tiporegra) }}</td>
                    <td>
                      <button class="button button-error" title="Remover" v-on:click="()=>removeRule(regra, index)">
                        <strong>&times;</strong>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <cupomProdutos
                v-if="hasRuleProdutos"
                :item="item"
                :produtos="produtos"
            />
            <div class="xrow">
              <div class="form-actions">
                <button :class="{ 'button button-success button-lg': true, 'button-disabled': validation.hasError() }"
                        v-on:click="handleSubmit">
                  Alterar
                </button>
                <button class="button button-lg" v-on:click="close">Fechar</button>
              </div>
            </div>
          </CTab>
          <CTab title="Clientes">
            <div class="row">
              <div class="col-12">
                <vue-dropzone :useCustomSlot=true ref="dropzone" id="dropzone"
                              v-on:vdropzone-success="uploadClientesdeArquivo"
                              v-on:vdropzone-removed-file="limpaClientesSelecionados" :options="dropzoneOptions">
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Arraste e solte para importar de um arquivo!
                    </h3>
                    <div class="dropzone-custom-subtitle">...ou clique para selecionar um arquivo do seu computador
                    </div>
                  </div>
                </vue-dropzone>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <span class="clientes filters__search">
                  <input type="text" v-model="filter" v-on:input="debounceloadClientes()" placeholder="Filtrar por id, cnpj ou razão social">
                  <img
                      src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"/>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="scrollable lista-clientes" style="height: 300px;">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                      <tr>
                        <th>ID</th>
                        <th>CNPJ / Razão Social</th>
                        <th>#</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="cliente in clientes">
                        <td>{{ cliente.id }}</td>
                        <td>
                          <small>{{ cliente.cnpj }}</small>
                          {{ cliente.razSoc }}
                        </td>
                        <td>
                          <button :disabled="clienteJaAdicionado(cliente.id)" 
                          v-on:click="addCliente(cliente)" 
                          class="button button-success">
                            <svg x="0px" y="0px" viewBox="0 0 42 42"
                                 style="width: 12px; height: 12px; fill: rgb(255, 255, 255);">
                              <path
                                  d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"></path>
                            </svg>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="scrollable lista-clientes" style="height: 300px;">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                      <tr>
                        <th colspan="3">
                          Clientes Vinculados
                          <span class="button button-link pull-right"
                                v-on:click="remTodosClientes()" 
                                title="Limpar todos">Limpar todos <fa-icon
                              icon="trash-alt"/></span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="cliente in item.clientes">
                        <td class="highlight">{{ cliente.id }}</td>
                        <td class="highlight"><small>{{ cliente.cnpj }}</small>
                          {{ cliente.razSoc }}</td>
                        <td class="actions">
                          <button type="button" name="button" 
                          v-on:click="remCliente(cliente.id)"
                                  class="button button-error">
                            <fa-icon icon="trash-alt"/>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </CTab>
          <CTab title="Exclusões">
            <div>
              <Exclusoes  :item="item" />
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>

<style scoped>
#dropzone {
  position: relative;
}

.dropzone-custom-content {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
}

.dropzone-custom-title {
  color: #00b782;
  font-size: 1.5rem;
  margin: 0 0 0.6rem;
  background-color: initial;
}

.dropzone-custom-subtitle {
  color: #314b5f;
}
</style>

<script>

import Vue from 'vue';

import setup from '../../config';
import SimpleVueValidation from 'simple-vue-validator';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _difference from 'lodash/difference';
import _reject from "lodash/reject";
import _differenceBy from "lodash/differenceBy";
import _concat from 'lodash/concat';
import _some from 'lodash/some';
import {toMonetary, isNumeric} from '../../helpers/utils';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import VueNumeric from 'vue-numeric';
import CloseModal from '../../components/CloseModal';
import cupomProdutos from './components/Produtos';
import _isEmpty from 'lodash/isEmpty';
import _debounce from "lodash/debounce";
import Exclusoes from './components/Exclusoes';
import { get, post, put, del } from "@/helpers/apiRequest";
import _map from "lodash/map";

Vue.component("Exclusoes", Exclusoes);

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: 'Campo de preenchimento obrigatório',
    greaterThan: 'Valor deve ser maior que {0}',
    lessThan: 'Valor deve ser menor que {0}',
  },
});

const dropzoneDictionary = {
  dictDefaultMessage:
      'Arraste um arquivo .xls(x) ou clique para importar clientes',
  dictFallbackMessage:
      'Seu navegador não suporta upload o recurso de arrastar e soltar',
  dictFileTooBig: 'O arquivo excedeu o tamanho máximo permitido (5MB)',
  dictInvalidFileType: 'Arquivo no formato inválido',
  dictResponseError: 'Erro ao processar solicitação, tente novamente!',
  dictCancelUpload: 'Cancelar',
  dictCancelUploadConfirmation: 'Tem certeza?',
};

const initialRule = () => ({
  valorMinimo: 1,
  dimensao: {
    id: '1',
    coluna: 1,
    tiporegra: 'D'
  },
});

const initialRuleForm = () => ({
  type: 'D',
  dim: 0,
});

export default {
  name: 'cupomEdit',
  props: [
    'item',
    'dimensoes',
    'produtos',
    'update',
    'close',
    'error',
    'descSingular',
  ],
  components: {Exclusoes},
  data() {
    return {
      newRule: initialRule(),
      newRuleForm: initialRuleForm(),
      hasRulePedido: false,
      hasRuleProdutos: false,
      hasRuleDimensoes: false,
      filter: '',
      resultLimit: 100,
      monetary: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        masked: false,
      },
      activeTab: '1',
      dropzoneOptions: {
        url: `${setup.api}/admin/cupons/importar-clientes`,
        acceptedFiles: '.xls, .xlsx',
        maxFilesize: 5,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        paramName: 'clientes',
        ...dropzoneDictionary,
      },
      clientes: [],
      clientesAdicionados: [],
      isChecked: false
    };
  },
  watch: {
    'item.clientes': function () {
      this.item.validarCliente = this.item.clientes.length !== 0;
    },
    'item.produtos': function () {
      this.item.validarProduto = this.item.produtos.length !== 0;
    },
    'item.regras': function () {
      this.hasRuleDimensoes = true;
      this.hasRulePedido = true;
      this.hasRuleProdutos = true;

      // Confere se tem regra de dimensões
      var verifyDimensaoRegras = null;
      verifyDimensaoRegras = this.item.regras.find(regras => regras.tiporegra === 'D');

      if (verifyDimensaoRegras == 'undefined' || verifyDimensaoRegras == null) {
        this.hasRuleDimensoes = false;
      }

      // Confere se tem regra de pedido
      var verifyPedidoRegras = null;
      verifyPedidoRegras = this.item.regras.find(regras => regras.tiporegra === 'P');

      if (verifyPedidoRegras == 'undefined' || verifyPedidoRegras == null) {
        this.hasRulePedido = false;
      }

      // Confere se tem regra de produtos
      var verifyProdutosRegras = null;
      verifyProdutosRegras = this.item.regras.find(regras => regras.tiporegra === 'PR');

      if (verifyProdutosRegras == 'undefined' || verifyProdutosRegras == null) {
        this.hasRuleProdutos = false;
      }
    },
  },
  computed: {
    getDimensoesItens() {
      if (this.dimensoes[this.newRuleForm.dim]) {
        return this.dimensoes[this.newRuleForm.dim].dimensoes;
      }
      return [];
    },
    isValorMinimoZero() {
      return this.newRule.valorMinimo == 0;
    }
  },
  validators: {
    'item.codigo': (value) => Validator.value(value).required(),
    'item.orientacao': (value) => Validator.value(value).required(),
    'item.valor': (value) =>
        Validator.value(value)
            .required()
            .greaterThan(0),
    'item.ativo': (value) => Validator.value(value).required(),
    'item.clientes, item.validarCliente': (clientes, validarCliente) => {
      if (!!validarCliente) {
        return Validator.value(clientes).required();
      }
      return;
    },
    'item.produtos, item.validarProduto': (produtos, validarProduto) => {
      if (!!validarProduto) {
        return Validator.value(produtos).required();
      }
      return;
    },
    'item.dataInicio': (value) => Validator.value(value).required(),
    'item.dataFim': (value) => Validator.value(value).required(),
    'item.regras': (value) => Validator.value(value).required(),
    'item.limiteUsos': (value) =>
        Validator.value(value)
            .required()
            .greaterThan(0),
    'item.valor_maximo_permitido': (value) => Validator.value(value).lessThan(1000000),
  },
  components: {
    vueDropzone: vue2Dropzone,
    VueNumeric,
    CloseModal,
    cupomProdutos,
  },
  methods: {
    beforeOpen(){
      this.loadClientes();
    },
    clientesDisponiveis(){
      return _differenceBy(this.clientes, this.item.clientes, 'id');
    },
    addCliente(cliente) {
      this.item.clientes.push(cliente);
    },
    addTodosClientes() {
      let clientes = this.clientesDisponiveis();

      for(let cliente of clientes) {
        this.addCliente(cliente);
      }
    },
    clienteJaAdicionado(idCliente) {
      return _find(this.item.clientes, {id: idCliente});
    },
    clientesJaAdicionados() {
      let clientes = this.clientesDisponiveis();
      return clientes.length === 0;
      
    },
    remCliente(idCliente) {
      this.item.clientes = _reject(this.item.clientes, {id: idCliente})
    },
    remTodosClientes() {
      this.item.clientes = [];
    },
    loadClientes(take = 50) {
      get(`/admin/cupons-clientes?take=${take}&q=${this.filter}`)
        .then((json) => {
          this.clientes = json.itens;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    debounceloadClientes: _debounce(function () {
      this.loadClientes();
    }, 300),
    resetTipointegracao() {
      this.item.tipoIntegracao = null;
    },
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          const valorMaximo = this.isChecked ? this.item.valor_maximo_permitido : null;
          const item = this.item
          item.valor_maximo_permitido = valorMaximo;
          this.update(item);
        }
      });
    },
    getDescricaoRegra(dimensao, tipoRegraDesc) {
      if (dimensao === null && tipoRegraDesc === 'P') {
        return 'Total do Pedido';
      }
      if (dimensao === null && tipoRegraDesc === 'PR') {
        return 'Regra de Produtos';
      }
      const dimensaoDesc = _find(
          this.dimensoes[dimensao.coluna - 1].dimensoes,
          (dim) => dimensao.id === dim.id
      );
      const dimensaoInfo = {
        quando: this.dimensoes[dimensao.coluna - 1].label,
        for: dimensaoDesc.desc,
      };
      return dimensaoInfo.quando + ' = ' + dimensaoInfo.for;
    },
    changeDimensao() {
      this.newRule.dimensao = {
        id: this.dimensoes[this.newRuleForm.dim].dimensoes[0].id,
        coluna: this.dimensoes[this.newRuleForm.dim].coluna,
      };
    },
    resetNewRule() {
      if (this.newRuleForm.type === 'P' || this.newRuleForm.type === 'PR') {
        this.newRule.dimensao = null;
      } else {
        this.changeDimensao();
      }
    },
    resetNewRuleForm() {
      this.newRuleForm = initialRuleForm();
      this.newRule = initialRule();
    },
    addRule(tipo) {
      const rule = this.newRule;

      if (tipo === 'P') {
        this.hasRulePedido = true;
      }
      if (tipo === 'PR') {
        this.hasRuleProdutos = true;
      }
      if (tipo === 'D') {
        this.hasRuleDimensoes = true;
      }

      rule.tiporegra = tipo;

      this.item.regras.push(rule);
      this.resetNewRuleForm();
    },
    removeRule(rule, i) {
      if (rule.tiporegra === 'P') {
        this.hasRulePedido = false;
      }
      if (rule.tiporegra === 'PR') {
        this.hasRuleProdutos = false;
      }

      this.item.regras.splice(i, 1);

      if (rule.tiporegra === 'D') {
        var verifyDimensaoRegras = null;
        verifyDimensaoRegras = this.item.regras.find(regras => regras.dimensao !== null);

        if (verifyDimensaoRegras == 'undefined' || verifyDimensaoRegras == null) {
          this.hasRuleDimensoes = false;
        }
      }
    },
    onRemoveCliente(id) {
      this.item.clientes = _filter(this.item.clientes, (idCliente) => {
        return +id !== +idCliente;
      });
    },
    changeTab(tab) {
      if (this.activeTab !== tab) {
        this.activeTab = tab;
      }
    },
    uploadClientesdeArquivo(arquivo, resposta) {
      const idsValidos = _filter(resposta.clientes, (id) => {
        return _find(this.clientes, (cliente) => `${cliente.id}` === `${id}`);
      });
      const newClients = _difference(idsValidos, this.item.clientes);
      this.item.clientes = _concat(this.item.clientes, newClients);
    },
    limpaClientesSelecionados() {
      this.$refs.dropzone.removeAllFiles();
      this.item.clientes = [];
    },
    toMonetary,
  },
};
</script>
